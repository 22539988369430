import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import {RouteWithLayout} from '../components';
import {Main as MainLayout} from '../layouts';
import {Minimal as MinimalLayout} from '../layouts';

import {
  Home as HomeView,

  Dashboard as DashboardView,
  DashboardRoute as DashboardRouteView,
  FrequentRoutes as FrequentRoutesView,

  Account as AccountView,

  LoadersList as LoadersListView,

  SystemSettings as SystemSettingsView,

  WantedList as WantedListView,

  WantedLists as WantedListsView,
  WantedListsItem as WantedListsItemView,
  WantedListsСoincidences as WantedListsСoincidencesView,
  WantedListPersonal as WantedListPersonalView,

  UsersList as UsersListView,
  UserCreate as UserCreateView,
  UserEdit as UserEditView,

  Logging as LoggingView,

  UnloadingTasks as UnloadingTasksView,

  Reference as ReferenceView,

  FeedbackList as FeedbackListView,

  HistoryNotifications as HistoryNotificationsView,

  // Мобильный розыск
  MobileWanted as MobileWantedView,
  WantedMobileList as WantedMobileListView,
  Archive as MobileArchiveView,

  FrequentStops as FrequentStopsView,

  UsersGroups as UsersGroupsPage,
  UsersGroupForm as UsersGroupFormPage,
  TransportList as TransportListPage,

  TransportConnectionsList as TransportConnectionsListView,
  TransportConnectionRoute as TransportConnectionRouteView,

  CamerasDashboard as CamerasDashboardView,
} from '../views';

const pagesMain = [
  {
    path: '/',
    component: HomeView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/multiscreen',
    component: CamerasDashboardView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/dashboard',
    component: DashboardView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/dashboard/route/:license_plate',
    component: DashboardRouteView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/dashboard/frequent-routes/:license_plate',
    component: FrequentRoutesView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/account',
    component: AccountView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/loaders',
    component: LoadersListView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/system-settings',
    component: SystemSettingsView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/notification',
    component: WantedListView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/notification-list',
    component: WantedListsView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/notification-list/:list_id',
    component: WantedListsItemView,
    exact: true,
    layout: MainLayout
  },
  {
    path: '/notification-lists-coincidences/:list_id',
    component: WantedListsСoincidencesView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/notification-list-personal',
    component: WantedListPersonalView,
    exact: true,
    layout: MainLayout,
  },


  {
    path: '/users',
    component: UsersListView,
    exact: true,
    layout: MainLayout,
  },
  // {
  //     path: '/users/created',
  //     component: AddNewUserView,
  //     exact: true,
  //     layout: MainLayout,
  // },
  {
    path: '/users/created',
    component: UserCreateView,
    exact: true,
    layout: MainLayout,
  },
  // {
  //     path: '/users/edit/:id',
  //     component: EditUserView,
  //     exact: true,
  //     layout: MainLayout,
  // },
  {
    path: '/users/edit/:id',
    component: UserEditView,
    exact: true,
    layout: MainLayout,
  },


  {
    path: '/logging',
    component: LoggingView,
    exact: true,
    layout: MainLayout,
  },


  {
    path: '/unloading-tasks',
    component: UnloadingTasksView,
    exact: true,
    layout: MainLayout,
  },


  {
    path: '/feedback-list',
    component: FeedbackListView,
    exact: true,
    layout: MainLayout,
  },


  {
    path: '/history-notifications',
    component: HistoryNotificationsView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/reference/:category?/:level2?/:level3?',
    component: ReferenceView,
    exact: true,
    layout: MainLayout,
  },
  // {
  //     path: '/reference/:reference_id',
  //     component: ReferencePageView,
  //     exact: true,
  //     layout: MainLayout,
  // },

  {
    path: '/mobile/notification',
    component: MobileWantedView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/mobile/notification/create',
    component: MobileWantedView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/mobile/notification-list',
    component: WantedMobileListView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/mobile/archive',
    component: MobileArchiveView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/frequent-stops',
    component: FrequentStopsView,
    exact: true,
    layout: MainLayout,
  },

  {
    path: '/users-groups',
    component: UsersGroupsPage,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/users-groups/:id',
    component: UsersGroupFormPage,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/users-groups/create',
    component: UsersGroupFormPage,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/transport-list',
    component: TransportListPage,
    exact: true,
    layout: MainLayout,
  },


  {
    path: '/track-control',
    component: TransportConnectionsListView,
    exact: true,
    layout: MainLayout,
  },
  {
    path: '/track-control/route/:license_plate',
    component: TransportConnectionRouteView,
    exact: true,
    layout: MainLayout,
  },
];

const Main = (props) => {
  return (
    <Switch>
      {
        pagesMain.map((page, idx) => (
          <RouteWithLayout
            key={'page-' + idx}
            {...page}
          />
        ))
      }

      <Redirect to="/not-found"/>
      <Redirect from="/" to="/dashboard"/>
    </Switch>
  );
};

export default Main
