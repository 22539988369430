import React, {Component} from 'react';
import {
  Box,
  Grid,
  Button,
  TextField,
  Container,
  Typography,
  Backdrop,
  CircularProgress,

  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@material-ui/core';
import {
  makeStyles
} from "@material-ui/styles";
import {sendMessages} from "../../../common/messages";
import axiosAgent from "../../../plugins/axios";
import {Image, Page} from "../../../components";
import varibles from "../../../varibles";
import {getLocalStorage} from "../../../helpers/localStorage";
import moment from "moment";
import {ExportCreate} from "../../dashboard/Dashboard/components";
import palette from "../../../theme/palette";
import axios from "../../../plugins/axios";
import {store} from "react-notifications-component";

class UsersGroupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: null,
      grzList: "",
      additionalGrzList: [],

      groupId: props?.match?.params?.id,
      isLoading: true,
      isBackdrop: false,
      isUserAdmin: Boolean(props?.app?.user?.role === 'admin')
    }
  }

  componentDidMount = async () => {
    const userRole = this.props?.app?.user?.role;

    if (userRole !== 'admin') {
      await this.initFormMe();
      return
    }
    await this.initForm();
  }

  initForm = async () => {
    const { groupId } = this.state;

    const group = await axiosAgent('get', `/user/group/get/${ groupId }`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    if (!group) {
      sendMessages({
        message: "Группы пользователей не найден",
        type: "danger"
      });
      this.props.history.push('/');
      this.setState({isLoading: false})
      return
    }

    const grzList = await axiosAgent('get', `/user-group/get-lp-list/${ groupId }`).then((res) => {
      return res.data || []
    }).catch(() => {
      return []
    });
    const additionalGrzList = await axiosAgent('get', `/user-group/get-add-lp-list/${ groupId }`).then((res) => {
      return res.data || []
    }).catch(() => {
      return []
    });

    this.setState({
      group,
      isLoading: false,
      grzList: grzList.join(" "),
      additionalGrzList
    });
  }
  initFormMe = async () => {
    const { groupId } = this.state;

    const group = await axiosAgent('get', `/user-group/get-current`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    if (!group) {
      sendMessages({
        message: "Группы пользователей не найден",
        type: "danger"
      });
      this.props.history.push('/');
      this.setState({isLoading: false})
      return
    }

    const grzList = await axiosAgent('get', `/user-group/get-lp-list/${ groupId }`).then((res) => {
      return res.data || []
    }).catch(() => {
      return []
    });
    const additionalGrzList = await axiosAgent('get', `/user-group/get-add-lp-list/${ groupId }`).then((res) => {
      return res.data || []
    }).catch(() => {
      return []
    });

    this.setState({
      group,
      grzList: grzList.join(" "),
      isLoading: false,
      additionalGrzList
    });
  }

  changeGroup = (group) => {
    this.setState({
      group
    })
  }
  changeGrzList = (grzList) => {
    this.setState({ grzList })
  }

  submit = async () => {
    this.setState({isBackdrop: true});
    const {
      group,
      groupId,
      grzList
    } = this.state;

    const bodyGroup = {
      ...group,
      user_limit: Number.parseFloat(group.user_limit || '0')
    };
    const resSaveGroup = await axiosAgent('post', `/user/group/edit/${ groupId }`, bodyGroup).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });

    const listGrz = (grzList || "").split(" ");
    const resSaveGrz = await axiosAgent('post', `/user-group/update-lp-list/${ groupId }`, listGrz).then((res) => {
      return res.data
    }).catch(() => {

    })

    sendMessages({
      message: "Группа пользователей успешно обновлена",
      type: "success"
    });
    this.setState({isBackdrop: false});
    this.props.history.push("/users-groups")
  }
  uploadFileGrz = async (file) => {
    this.setState({ isBackdrop: true });

    const { groupId } = this.state;
    const formData = new FormData();
    formData.append('id', groupId);
    formData.append('file', file);
    const res = await axiosAgent('post', `/user-group/upload-lp-list/${ groupId }`, formData).then((res) => {
      return res.data
    }).catch(() => {
      return null
    })

    this.setState({ isBackdrop: false });
    sendMessages({
      message: "Список ГРЗ обновлен",
      type: "success"
    })
    this.props.history.push("/users-groups")
  }
  exportFileGrz = async () => {
    this.setState({ isBackdrop: true });
    const actionUrl = `${varibles.API_URL}/user-group/download-lp-list/${ this.state.groupId }?token=${getLocalStorage('session_id')}`;
    const file = await fetch(actionUrl, {
      method: "GET"
    }).then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `grz-list-${this.state.groupId}.txt`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      });
    this.setState({ isBackdrop: false });
  }

  exportAdditionalGrzList = async (paramsExport) => {
    this.setState({ isBackdrop: true });

    let bodySend = {};
    bodySend['type'] = (paramsExport.type || '')
      .replace('export_', 'additional_lps_')
      .replace('_photo', '');
    bodySend['encoding'] = Number(paramsExport.encoding);

    axios('post', '/export/create', bodySend).then(response => {
      this.setState({ isBackdrop: false });
      store.addNotification({
        title: 'Успешно',
        message: 'Задача на выгрузку успешно создана',
        type: 'success',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
          duration: 3000,
          onScreen: false,
          pauseOnHover: true,
          delay: 0
        }
      });
    }).catch(error => {
      this.setState({ isBackdrop: false });
    })
  }

  render () {
    const {
      group,
      grzList,
      isLoading,
      isBackdrop,
      isUserAdmin,
      additionalGrzList
    } = this.state;

    if (isLoading) {
      return null
    }
    return (
      <Page>
        <Container maxWidth="md">
          <GroupSection
            value={group}
            isUserAdmin={isUserAdmin}
            onChange={this.changeGroup}
          />
          <Box mt={2}/>
          <GrzSection
            value={grzList}
            isUserAdmin={isUserAdmin}
            onChange={this.changeGrzList}
            onUpload={this.uploadFileGrz}
            onExport={this.exportFileGrz}
          />
          <Box mt={2}/>
          <AdditionalGrzList
            list={additionalGrzList}
            onExport={this.exportAdditionalGrzList}
          />
          <Box mt={2}/>
          <VisibleContent visible={Boolean(isUserAdmin)}>
            <Grid container justify="flex-end">
              <Grid item xs={6}>
                <Button variant="contained" fullWidth onClick={this.submit}>
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </VisibleContent>
        </Container>

        <Backdrop open={isBackdrop} style={{zIndex: 999}}>
          <CircularProgress/>
        </Backdrop>
      </Page>
    );
  }
}
const GroupSection = React.memo((props) => {
  const {
    value,
    isUserAdmin,
    onChange
  } = props;
  const classes = useStyles();

  const handleChange = ({ target }) => {
    let newVal = {...value};
    newVal[target.name] = target.value;
    onChange(newVal);
  }

  return (
    <Box className={classes.whiteSection}>
      <Typography variant="h3">Группа пользователей</Typography>
      <Box mt={2}/>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            value={value.Title}
            disabled={!isUserAdmin}
            name="Title"
            placeholder="..."
            label="Наименование группы"
            variant="outlined"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={value.user_limit}
            disabled={!isUserAdmin}
            name="user_limit"
            placeholder="..."
            label="Пользовательский лимит"
            variant="outlined"
            type="number"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  )
});
const GrzSection = React.memo((props) => {
  const {
    value,
    isUserAdmin,
    onChange,
    onUpload,
    onExport
  } = props;
  const classes = useStyles();

  const handleChange = ({ target }) => {
    onChange(target.value)
  }
  const handleChangeFile = ({ target }) => {
    const { files } = target;
    const file = files?.[0] || null;
    if (!file) {
      return
    }
    onUpload(file);
  }

  return (
    <Box className={classes.whiteSection}>
      <Typography variant="h3">Разрешенные ГРЗ</Typography>
      <Box mt={2}/>
      <TextField
        value={value}
        disabled={!isUserAdmin}
        variant="outlined"
        placeholder="В888ВВ88 В888ВВ88 В888ВВ88 В888ВВ88 В888ВВ88"
        helperText={isUserAdmin ? 'ГРЗ заполняются через пробел' : ''}
        multiline
        fullWidth
        rows={8}
        onChange={handleChange}
      />
      <VisibleContent visible={isUserAdmin}>
        <Box mt={4}/>
        <Grid container spacing={2}>
          <Grid item>
            <label>
              <Button variant="contained" component="span">
                Загрузить через файл
              </Button>
              <input type="file" accept="text/plain" hidden onChange={handleChangeFile}/>
            </label>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={onExport}>
              Выгрузить список ГРЗ
            </Button>
          </Grid>
        </Grid>
      </VisibleContent>
    </Box>
  )
});
const AdditionalGrzList = React.memo((props) => {
  const {
    list,
    onExport
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.whiteSection}>
      <Typography variant="h3">Дополнительный список ГРЗ</Typography>

      <Table className={classes.tableAdditionalGrzList}>
        <TableHead>
          <TableRow>
            <TableCell>Фото</TableCell>
            <TableCell>ГРЗ</TableCell>
            <TableCell>Пользователь</TableCell>
            <TableCell>Причина внесения ТС</TableCell>
            <TableCell align="right">Дата создания</TableCell>
            <TableCell align="right">Дата истечения</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => (
            <TableRow>
              <TableCell>
                <Image
                  src={[process.env.REACT_APP_IMAGE_URL, row.filename].join('')}
                  classImage={classes.tableImage}
                  name={'image'}
                  filterName="image"
                />
              </TableCell>
              <TableCell>{ row.license_plate }</TableCell>
              <TableCell>{ row.username }</TableCell>
              <TableCell>{ row.reason }</TableCell>
              <TableCell align="right">{ moment(row.created_at).format("DD.MM.YYYY HH:mm:ss") }</TableCell>
              <TableCell align="right">{ moment(row.expires).format("DD.MM.YYYY HH:mm:ss") }</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box mt={2}/>

      <Box className={classes.buttonExportAdditional}>
        <ExportCreate
          onExportFile={onExport}
        />
      </Box>
    </Box>
  )
})
const VisibleContent = React.memo(({ visible, children }) => {
  if (!visible) {
    return null
  }
  return children
})

const useStyles = makeStyles(() => ({
  whiteSection: {
    borderRadius: "4px",
    background: "#FFF",
    boxShadow: "0px 2px 8px 0px rgba(142, 150, 168, 0.24)",
    padding: 24,
    boxSizing: "border-box"
  },

  tableAdditionalGrzList: {
    marginTop: 10,
    "& .MuiTableCell-head": {
      padding: "6px 12px"
    },
    "& .MuiTableCell-body": {
      padding: "6px 12px"
    },
    "& .MuiTableBody-root .MuiTableRow-root": {
      "&:hover": {
        backgroundColor: "#edeeef"
      }
    },

    "& img": {
      width: 64,
      height: 64,
      borderRadius: 8,
      objectFit: "cover"
    }
  },
  buttonExportAdditional: {
    "& .MuiButtonBase-root": {
      backgroundColor: palette.primary.main,
      padding: "10px 15px!important",
      color: "white",
      fontWeight: "500"
    }
  }
}));

export default UsersGroupForm
export {
  GrzSection
}
