import React, {Component} from 'react';
import {
	Box,
	Grid,
	Button,
	Backdrop,
	TextField,
	Container,
	Typography,
	CircularProgress, Table, TableHead, TableRow, TableCell, TableBody,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import {
	Autocomplete
} from "@material-ui/lab";
import {
	makeStyles
} from "@material-ui/styles";
import {
	Image,
	Page,
} from "../../../components";
import axiosAgent from "../../../plugins/axios";
import varibles from "../../../varibles";
import {getLocalStorage} from "../../../helpers/localStorage";
import {sendMessages} from "../../../common/messages";
import moment from "moment/moment";
import {ExportCreate} from "../../dashboard/Dashboard/components";
import clsx from "clsx";
import {getBlobFromUrl} from "../../../helpers/files";

class TransportList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			group: null,
			groupId: null,

			grzList: "",
			additionalGrzList: [],
			isLoadingGroupInfo: false,
			openAddingAdditionalGrz: false,

			isBackdrop: false,
		}
	}

	componentDidMount = async () => {
		await this.getMyGroup();
	}

	getMyGroup = async () => {
		const res = await axiosAgent('get', '/user-group/get-current')
			.then((res) => res.data || null)
			.catch(() => null);

		await this.setState({
			group: res,
			groupId: res?.id
		});
		await this.getGroupInfo();
	}
	getGroupInfo = async () => {
		const { groupId } = this.state;

		await this.initGrzList();
		await this.initAdditionalGrzList();

		await this.setState({
			isLoadingGroupInfo: false
		});
	}

	initGrzList = async () => {
		const grzList = await axiosAgent('get', `/user-group/get-lp-list/${ this.state.groupId }`).then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		});
		await this.setState({
			grzList: grzList.join(' '),
		})
	};
	exportGrzList = async () => {
		this.setState({ isBackdrop: true });
		const actionUrl = `${varibles.API_URL}/user-group/download-lp-list/${ this.state.groupId }?token=${getLocalStorage('session_id')}`;
		const file = await fetch(actionUrl, {
			method: "GET"
		}).then(response => response.blob()).then(blob => {
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = `grz-list-${this.state.groupId}.txt`;
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove();
			});
		this.setState({ isBackdrop: false });
	}
	submitGrzList = async () => {
		await this.setState({ isBackdrop: true });
		const { groupId, grzList } = this.state;
		const isSuccess = await axiosAgent("post", `/user-group/update-lp-list/${ groupId }`, (grzList.split(' ')))
			.then(() => true)
			.catch(() => false);
		if (!isSuccess) {
			await this.setState({ isBackdrop: false });
			sendMessages({
				type: "danger",
				message: "Произошла ошибка ручного сохранения списка ГРЗ, обратитесь к менеджеру."
			})
			return
		}

		await this.initGrzList();
		await this.setState({ isBackdrop: false });
		sendMessages({
			message: "Список ГРЗ обновлен",
			type: "success"
		});
	}
	uploadGrzList = async (file) => {
		this.setState({ isBackdrop: true });

		const { groupId } = this.state;
		const formData = new FormData();
		formData.append('id', groupId);
		formData.append('file', file);
		const res = await axiosAgent('post', `/user-group/upload-lp-list/${ groupId }`, formData).then((res) => {
			return res.data
		}).catch(() => {
			return null
		})

		await this.initGrzList();
		this.setState({ isBackdrop: false });
		sendMessages({
			message: "Список ГРЗ обновлен",
			type: "success"
		})
	}
	changeGrzList = async (grzList) => {
		await this.setState({ grzList });
	}

	initAdditionalGrzList = async () => {
		const additionalGrzList = await axiosAgent('get', `/user-group/get-add-lp-list/${ this.state.groupId }`).then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		});
		await this.setState({
			additionalGrzList,
		})
	}
	createAdditionalGrz = async (form) => {
		await this.setState({ isBackdrop: true });
		const file = await getBlobFromUrl(require('../../../assets/image/1x1.png'));
		const formData = new FormData();
		formData.append("file", file);
		formData.append("data", JSON.stringify(form));
		const res = await axiosAgent('post', '/operator/create-additional-lp', formData).then((res) => {
			return res?.data
		}).catch((err) => {
			return {error: err.response?.details || "Ошибка сервера, обратитесь к менеджеру."}
		});
		if (res?.error) {
			await this.setState({ isBackdrop: false });
			sendMessages({
				type: "danger",
				message: res?.error
			});
			return
		}
		await this.initAdditionalGrzList();
		await this.setState({
			isBackdrop: false,
			openAddingAdditionalGrz: false
		});

	}
	changeOpenAddingAdditionalGrz = (value) => {
		this.setState({ openAddingAdditionalGrz: value })
	}

	render() {
		const {
			group,
			groupId,

			grzList,
			additionalGrzList,
			openAddingAdditionalGrz,

			isBackdrop,
		} = this.state;
		const {
			user
		} = this.props;
		const isOutdoor = Boolean(user?.additional_roles?.outdoor);

		return (
			<Page>
				<Container maxWidth="md">
					<Typography variant="h1">Список ТС</Typography>
					<Box mb={1}/>
					<SelectGroupId group={group}/>

					<VisibleContent visible={groupId}>
						<Box mb={2}/>
						<GrzSection
							value={grzList}
							onChange={this.changeGrzList}
							onExport={this.exportGrzList}
							onUpload={this.uploadGrzList}
							onSubmit={this.submitGrzList}
						/>
						<Box mb={2}/>
						<AdditionalGrzList
							list={additionalGrzList}
							isOutdoor={isOutdoor}
							onOpenForm={this.changeOpenAddingAdditionalGrz.bind(null, true)}
						/>
					</VisibleContent>
					<VisibleContent visible={!groupId}>
						<BoxInformation
							title="Предупреждение"
							message="Выберите группу пользователей"
						/>
					</VisibleContent>
				</Container>

				<AddingAdditionalGrz
					open={openAddingAdditionalGrz}
					onCreate={this.createAdditionalGrz}
					onClose={this.changeOpenAddingAdditionalGrz.bind(null, false)}
				/>
				<Backdrop open={isBackdrop} invisible={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</Page>
		);
	}
}

const SelectGroupId = ({ group }) => {
	const classes = useStyles();

	return (
		<Box className={classes.section}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TextField
						value={group?.Title}
						variant="outlined"
						label="Группа пользователей"
						InputLabelProps={{shrink: true}}
						fullWidth
						disabled
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						value={group?.user_limit}
						variant="outlined"
						label="Лимит пользователей"
						InputLabelProps={{shrink: true}}
						fullWidth
						disabled
					/>
				</Grid>
			</Grid>
		</Box>
	)
}
const GrzSection = React.memo((props) => {
	const {
		value,
		onChange,
		onUpload,
		onExport,
		onSubmit
	} = props;
	const classes = useStyles();

	const handleChange = ({ target }) => {
		onChange(target.value)
	}
	const handleChangeFile = ({ target }) => {
		const { files } = target;
		const file = files?.[0] || null;
		if (!file) {
			return
		}
		onUpload(file);
	}

	return (
		<Box className={classes.section}>
			<Typography variant="h3">Разрешенные ГРЗ</Typography>
			<Box mt={2}/>
			<TextField
				value={value}
				disabled
				variant="outlined"
				placeholder="В888ВВ88 В888ВВ88 В888ВВ88 В888ВВ88 В888ВВ88"
				multiline
				fullWidth
				rows={8}
				onChange={handleChange}
			/>
			{Boolean(false) && (
				<>
					<Box mt={4}/>
					<Grid container justify="space-between">
						<Grid item>
							<Grid container spacing={2}>
								<Grid item>
									<label>
										<Button variant="contained" component="span">
											Загрузить через файл
										</Button>
										<input type="file" accept="text/plain" hidden onChange={handleChangeFile}/>
									</label>
								</Grid>
								<Grid item>
									<Button variant="outlined" onClick={onExport}>
										Выгрузить список ГРЗ
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Button variant="contained" onClick={onSubmit}>
								Сохранить ручной ввод
							</Button>
						</Grid>
					</Grid>
				</>
			)}
		</Box>
	)
});
const AdditionalGrzList = React.memo((props) => {
	const {
		list,
		isOutdoor,
		onExport,
		onOpenForm
	} = props;
	const classes = useStyles();

	return (
		<Box className={classes.section}>
			<Typography variant="h3">Дополнительный список разрешенных ГРЗ</Typography>
			<Box mt={2}/>

			<Table className={classes.additionalGrzListTable}>
				<TableHead>
					<TableRow>
						<TableCell>ГРЗ</TableCell>
						<TableCell>Пользователь</TableCell>
						<TableCell>Причина внесения ТС</TableCell>
						<TableCell align="right">Дата создания</TableCell>
						<TableCell align="right">Дата истечения</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{list.map((row) => (
						<TableRow>
							<TableCell>{ row.license_plate }</TableCell>
							<TableCell>{ row.username }</TableCell>
							<TableCell>{ row.reason }</TableCell>
							<TableCell align="right">{ moment(row.created_at).format("DD.MM.YYYY HH:mm:ss") }</TableCell>
							<TableCell align="right">{ moment(row.expires).format("DD.MM.YYYY HH:mm:ss") }</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			<Box mt={2}/>

			<Grid container justify="space-between">
				<Grid item>
					<ExportCreate
						buttonVariant="outlined"
						onExportFile={onExport}
					/>
				</Grid>
				<Grid item>
					<VisibleContent visible={isOutdoor}>
						<Button variant="contained" onClick={onOpenForm}>
							Добавить
						</Button>
					</VisibleContent>
					<VisibleContent visible={!isOutdoor}>
						<Typography variant="body2">
							Для добавление ГРЗ у вас должна быть дополнительная роль "НАРУЖКА"
						</Typography>
					</VisibleContent>
				</Grid>
			</Grid>
		</Box>
	)
});
const AddingAdditionalGrz = React.memo((props) => {
	const {
		onClose,
		onCreate
	} = props;
	const initForm = {reason: "", license_plate: ""};

	const [form, setForm] = React.useState({ ...initForm });
	React.useEffect(() => {
		setForm({...initForm})
	}, [props.open]);
	const handleChangeForm = ({ target }) => {
		const { name, value } = target;
		let _form = {...form};
		_form[name] = value;
		setForm(_form);
	}
	const handleSubmitForm = () => {
		onCreate(form);
	}

	return (
		<Dialog
			open={props.open}
			fullWidth
			maxWidth="sm"
			onClose={onClose}
		>
			<DialogTitle>
				<Typography variant="h3">Добавление ГРЗ в дополнительный список</Typography>
			</DialogTitle>
			<DialogContent>
				<BoxInformation
					title="Внимание!"
					message="Уважаемый пользователь!<br/><br/>Обращаем внимание, что Вы запрашиваете информацию, незаконное использование которой может повлечь уголовную ответственность (ст. 137 УК РФ).<br/><br/>Информация о Ваших зафиксированных и сохранённых в системе действиях может быть предоставлена по запросу уполномоченных государственных органов в соответствии с законодательством Российской Федерации.<br/><br/>Пожалуйста, убедитесь, что Ваши действия осуществляются уполномоченным лицом в рамках правомерной деятельности, на реализацию которой направлена эксплуатация системы.<br/><br/>В противном случае следует отказаться от выполнения соответствующих действий."
					rmmt dark
				/>
				<Box mt={1}/>
				<Button fullWidth variant="contained" onClick={onClose}>Отменить</Button>

				<Box mt={2}/>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="formTitle">ГРЗ автомобиля *</Typography>
						<TextField
							value={form.license_plate}
							fullWidth
							variant="outlined"
							name="license_plate"
							onChange={handleChangeForm}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="formTitle">Причина внесения *</Typography>
						<TextField
							value={form.reason}
							rows={3}
							fullWidth
							multiline
							variant="outlined"
							name="reason"
							onChange={handleChangeForm}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							disabled={Boolean((form.license_plate || '').length < 6 || !form.reason)}
							variant="contained"
							onClick={handleSubmitForm}
						>Добавить</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
});

const BoxInformation = ({ title, message, rmmt, dark }) => {
	const classes = useStyles();
	return (
		<Box
			className={clsx({
				[classes.boxInformation]: true,
				'--rmmt': rmmt,
				'--dark': dark,
			})}
		>
			<Typography className={classes.boxInformationTitle}>{ title }</Typography>
			<Typography
				className={classes.boxInformationMessage}
				dangerouslySetInnerHTML={{ __html: message }}
			/>
		</Box>
	)
}
const VisibleContent = ({ visible, children }) => {
	if (!visible) return null;
	return children || null
}

const useStyles = makeStyles(() => ({
	section: {
		padding: "16px 20px",
		background: "white",
		borderRadius: "12px",
		boxShadow: "0px 3px 5px 0px #00000014",
	},
	sectionTitle: {},

	boxInformation: {
		marginTop: "12px",
		padding: "12px 20px",
		background: "white",
		borderRadius: "12px",
		boxShadow: "0px 3px 5px 0px #00000014",

		"&.--rmmt": {
			marginTop: 0
		},
		"&.--dark": {
			background: "#F4F6F8",
		}
	},
	boxInformationTitle: {
		color: "black",
		fontSize: "18px",
		fontWeight: "500",
		lineHeight: "21px"
	},
	boxInformationMessage: {
		marginTop: "6px",
		opacity: 0.8,

		color: "black",
		fontSize: "16px",
		lineHeight: "20px"
	},

	additionalGrzListTable: {},
}));

export default TransportList
