// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import TransportList from './TransportList';

export default compose(
  connect(
    state => ({
      user: state?.app?.user
    }),
    dispatch => ({}),
  ),
)(TransportList);
