import {sendMessages} from "../common/messages";

const downloadFile = async (linkFile) => {
	const file = await fetch(linkFile)
		.then((res) => {
			return res.blob()
		})
		.catch((err) => {
			return {error: err}
		});

	if (file.error) {
		sendMessages({
			message: file.error?.message || "Ошибка скачивания файла.",
			type: "danger"
		})
		return
	}

	const fileBlob = file;
	const fileName = (file?.url || "").split("/").pop();

	var link = document.createElement('a');
	link.href = window.URL.createObjectURL(fileBlob);
	link.download = fileName;

	document.body.appendChild(link);

	link.click();
}
const getBlobFromUrl = (myImageUrl, fileName) => {
	return new Promise((resolve, reject) => {
		let request = new XMLHttpRequest();
		request.open('GET', myImageUrl, true);
		request.responseType = 'blob';
		request.onload = () => {
			resolve(request.response);
		};
		request.onerror = reject;
		request.send();
	})
}


export {
	downloadFile,
	getBlobFromUrl
}
